
.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: flex-end!important;
}
.pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

  
}
.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}
.pagination-container .pagination-item.selected {
    background-color: #00CC99;
}
.selected{
    background-color: #fff !important;
    cursor: default;
    color:white !important;
}
.selected1{
    background-color: #00CC99 !important;
    cursor: default;
    color:white !important;
}
.pagination-container .pagination-item .arrow::before {
    position: relative;
   /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
   /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
    pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-container .pagination-item .fa-solid {
    border-radius: 30px !important;
    width: 37px;
    height: 37px;
    font-size: 16px;
    background-color: #E9E9E9 !important;
    color: #00CC99;
    border: none;
    text-align: center;
    position: relative;
    display: block;
    padding: 0.5rem 0.7rem;
    font-size:1rem !important;
    color: #E9E9E9;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid  #dee2e6;;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   
}
.pagination-container .pagination-item .page-num{
    border: none;
    color: #393939 !important;
    font-size: 16px !important;
    border-radius: 30px !important;
    width: 37px !important;
    height: 37px !important;
    text-align: center !important;
}
.pagination-container .pagination-item .page-num:hover {
    color: #fff !important;
    background-color: #00CC99 !important;
}
.page-dots {
    border: none !important;
    color: #393939;
    font-size: 16px;
    text-align: center;
}
